import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowForwardIos, Add } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from '@mui/material';

import { SmallIconBtn } from 'components/UI/Buttons';

import { useGetUserFilesQuery, useSendFileMessageMutation } from '../../../services/api/chat.api';

const BankStatements: FC = () => {
  const { data, isLoading } = useGetUserFilesQuery(null);
  const [sendFileMessage] = useSendFileMessageMutation();
  const navigate = useNavigate();

  const handleSendFile = (file: File) => {
    sendFileMessage({ file }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error sending file', error);
    });
    navigate('/chat');
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleSendFile(file);
    }
  };

  return (
    <Box>
      {isLoading && <div>Loading...</div>}
      {data && (
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            scrollBehavior: 'smooth',
            padding: 2,
          }}
        >
          {data.map((file) => (
            <Card key={file.id} sx={{ minWidth: 200, maxWidth: 200, margin: 1, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {file.fileName?.split('/').pop()}
                </Typography>
                <Box display="flex" justifyContent="flex-end" mt={1}>
                  <SmallIconBtn onClick={() => window.open(file.url as string, '_blank')}>
                    <ArrowForwardIos />
                  </SmallIconBtn>
                </Box>
              </CardContent>
            </Card>
          ))}
          <Card sx={{ minWidth: 200, margin: 1 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Subir archivo
              </Typography>
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <form>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileUpload}
                      aria-label="Upload file"
                      accept="image/*,.pdf"
                    />
                    <SmallIconBtn component="span">
                      <Add />
                    </SmallIconBtn>
                  </label>
                </form>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default BankStatements;
