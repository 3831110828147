import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Grid, Typography, Avatar } from '@mui/material';

import { BankOffer } from '@relif/backend';
import showDebtIcon from 'utils/showDebtIcon';

interface BankOfferCardProps {
  offer: BankOffer;
  isLastRow: boolean;
}

const BankOfferCard = ({ offer, isLastRow }: BankOfferCardProps) => {
  return (
    <Link to={`/bank-offers/${offer.uuid}?withLayout=true`} style={{ textDecoration: 'none', color: 'inherit' }}>
      <Box
        sx={{
          py: 1,
          borderBottom: isLastRow ? 'none' : '1px solid #e0e0e0',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            cursor: 'pointer',
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                height: '100%',
              }}
            >
              <Avatar
                sx={{
                  bgcolor: '#EDF1FA',
                  color: 'primary.main',
                  width: '40px',
                  height: '40px',
                }}
              >
                {showDebtIcon(
                  {
                    institutionName: offer.bank,
                    institutionType: 'Banco',
                    totalAmount: 0,
                    totalMonthlyPayment: 0,
                    source: 'zeller',
                    debtType: 'credito',
                  },
                  40
                )}
              </Avatar>
            </Box>
          </Grid>
          <Grid item xs={6} px={1}>
            <Typography variant="h4" fontWeight={400} fontSize="16px">
              {offer.bank}
            </Typography>
            <Typography variant="h5" fontSize="12px">
              Tasa: {(Number(offer.interestRate) * 100).toFixed(2)}%
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h4" fontWeight={400} fontSize="16px" textAlign="right">
              ${Number(offer.paymentPlanAmount).toLocaleString('es-CL')}
            </Typography>
            {offer.description && (
              <Typography variant="h5" fontSize="12px" textAlign="right">
                {offer.description}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Link>
  );
};

export default BankOfferCard;
