import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Button, Modal, TextField, Typography } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import { auth } from 'fireBaseConfig';
import { getRedirectResult } from 'firebase/auth';
import Error from 'pages/Error/ErrorAlert';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import {
  useSignInUserMutation,
  useFinancialAdvisorSignUpUserMutation,
  usePasswordRecoveryMutation,
  useExternalAuthProviderSignInMutation,
} from 'services/api/user.api';
import signInWithProvider from 'utils/authProviders';
import getReCaptchaToken from 'utils/getReCaptchaToken';
import { identifyPosthogUser } from 'utils/posthogUtils';
import validateEmail from 'utils/validations/validateEmail';
import validatePassword, { getPasswordErrorMessage } from 'utils/validations/validatePassword';

import EmailLoginButton from '../EmailLoginButton';
import GoogleLoginButton from '../GoogleLoginButton';

interface FinancialAdvisorAuthModalProps {
  open: boolean;
  onClose: () => void;
  companyId: number;
  defaultIsExistingUser: boolean;
  source?: string;
}

const FinancialAdvisorAuthModal = ({
  open,
  onClose,
  companyId,
  defaultIsExistingUser,
  source,
}: FinancialAdvisorAuthModalProps) => {
  const [isExistingUser, setIsExistingUser] = useState(defaultIsExistingUser);
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [signIn, { data: signInData, isLoading: signInLoading, error: signInError }] = useSignInUserMutation();
  const [signUp, { data: signUpData, isLoading: signUpLoading, error: signUpError }] =
    useFinancialAdvisorSignUpUserMutation();
  const [externalAuthProviderSignIn, { data: externalAuthProviderSignInData, error: externalAuthProviderSignInError }] =
    useExternalAuthProviderSignInMutation();
  const [passwordRecovery, { error: errorPasswordRecovery }] = usePasswordRecoveryMutation();
  const [noEmailError, setNoEmailError] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const dispatch = useDispatch();

  const emailVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (!event.target.value) {
      setEmailError(false);
    } else {
      setEmailError(!validateEmail(event.target.value));
    }
  };

  const passwordVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (!event.target.value) {
      setPasswordError(false);
    } else {
      setPasswordError(!validatePassword(event.target.value, '', email));
    }
  };

  const passwordConfirmVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);
    if (!event.target.value) {
      setPasswordConfirmError(false);
    } else {
      setPasswordConfirmError(password !== event.target.value);
    }
  };

  const verifySignUpForm = () => {
    const values = !!email && !!password && !!passwordConfirm;
    const errors = emailError || passwordError || passwordConfirmError;
    return values && !errors;
  };

  const verifySignInForm = () => {
    const values = !!email && !!password;
    const errors = emailError || passwordError;
    return values && !errors;
  };

  const verifyForm = () => {
    return isExistingUser ? verifySignInForm() : verifySignUpForm();
  };

  const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const recaptchaToken = await getReCaptchaToken();
    await signIn({ email, password, recaptchaToken }).catch(() => {
      console.error('Error signing in'); // eslint-disable-line no-console
    });
  };

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const recaptchaToken = await getReCaptchaToken();

    await signUp({ email, password, recaptchaToken, companyId, source }).catch(() => {
      console.error('Error signing up'); // eslint-disable-line no-console
    });
  };

  const handleExternalAuthProviderSignIn = async (provider: 'google' | 'facebook') => {
    // Save companyId and source to local storage
    localStorage.setItem('companyId', companyId.toString());
    if (source) {
      localStorage.setItem('source', source);
    }
    await signInWithProvider(provider);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!verifyForm()) return;
    if (isExistingUser) {
      await handleSignIn(event).catch(() => {});
    } else {
      await handleSignUp(event).catch(() => {});
    }
  };

  const handlePasswordRecovery = () => {
    if (email) {
      setNoEmailError(false);
      if (!validateEmail(email)) {
        setInvalidEmail(true);
        return;
      }
      passwordRecovery({ email }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    } else {
      setNoEmailError(true);
    }
  };

  const emailInputHelperText = () => {
    if (noEmailError) {
      return 'Por favor, ingresa un email para recuperar tu contraseña';
    }
    if (invalidEmail) {
      return 'Por favor, ingresa un email válido';
    }
    return '';
  };

  useEffect(() => {
    const data = signInData || signUpData || externalAuthProviderSignInData;
    if (data) {
      identifyPosthogUser(data);
      dispatch(setAccessToken({ accessToken: data.accessToken }));
    }
  }, [signInData, signUpData, externalAuthProviderSignInData]);

  useEffect(() => {
    setIsExistingUser(defaultIsExistingUser);
  }, [defaultIsExistingUser]);

  const fetchExternalAuthProviderSignIn = async () => {
    const result = await getRedirectResult(auth);
    // eslint-disable-next-line no-console
    console.log('🔀 getRedirectResult:', result);
    if (!result) return;
    const { user } = result;
    if (!user) return;
    const { token } = await user.getIdTokenResult();
    const recaptchaToken = await getReCaptchaToken();

    // Retrieve companyId and source from local storage
    const storedCompanyId = localStorage.getItem('companyId');
    const storedSource = localStorage.getItem('source');

    await externalAuthProviderSignIn({
      recaptchaToken,
      token,
      companyId: storedCompanyId ? parseInt(storedCompanyId, 10) : companyId,
      source: storedSource || source,
    }).catch(() => {});
  };

  useEffect(() => {
    fetchExternalAuthProviderSignIn().catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error fetching external auth provider sign in', error);
    });
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: '10px',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          width: { xs: '100%', sm: '500px' },
          minHeight: '500px',
        }}
      >
        {isEmailLogin ? (
          <Box
            component="form"
            // eslint-disable-next-line no-void
            onSubmit={(e) => void handleSubmit(e)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              justifyContent: 'center',
              alignItems: 'center',
              width: { xs: '100%', sm: '300px' },
            }}
          >
            <Box sx={{ width: { xs: '100%', sm: '300px' } }}>
              <GoogleLoginButton
                isExistingUser={isExistingUser}
                // eslint-disable-next-line no-void
                onClick={() => void handleExternalAuthProviderSignIn('google')}
              />
            </Box>
            <Typography variant="body1"> o </Typography>
            {isExistingUser ? (
              <Box>
                <Typography variant="body1">Iniciar sesión con tus datos</Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="body1">Crear cuenta con tus datos, para poder chatear con el asistente</Typography>
              </Box>
            )}
            <Box>
              <TextField
                label="Email"
                value={email}
                onChange={emailVerify}
                error={emailError || noEmailError || invalidEmail}
                helperText={emailError ? 'Email inválido' : emailInputHelperText()}
                sx={{ width: '300px' }}
              />
            </Box>
            <Box>
              <TextField
                label="Contraseña"
                value={password}
                onChange={passwordVerify}
                error={passwordError}
                type="password"
                helperText={passwordError && getPasswordErrorMessage(password, '', email)}
                sx={{ width: '300px' }}
              />
            </Box>
            {!isExistingUser && (
              <Box>
                <TextField
                  label="Confirmar contraseña"
                  value={passwordConfirm}
                  onChange={passwordConfirmVerify}
                  error={passwordConfirmError}
                  type="password"
                  helperText={passwordConfirmError ? 'Las contraseñas no coinciden' : ''}
                  sx={{ width: '300px' }}
                />
              </Box>
            )}
            <Box>
              <PrimaryBtn
                type="submit"
                variant="contained"
                color="primary"
                disabled={signInLoading || signUpLoading || !verifyForm()}
              >
                {isExistingUser ? 'Iniciar sesión' : 'Registrarse'}
              </PrimaryBtn>
              {signInError && 'data' in signInError && <Error message={signInError.data as string} isOpen />}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center' }}
          >
            <Box sx={{ width: { xs: '100%', sm: '300px' } }}>
              {externalAuthProviderSignInError && 'data' in externalAuthProviderSignInError && (
                <Error message={externalAuthProviderSignInError.data as string} isOpen />
              )}
              <GoogleLoginButton
                isExistingUser={isExistingUser}
                // eslint-disable-next-line no-void
                onClick={() => void handleExternalAuthProviderSignIn('google')}
              />
            </Box>
            <Typography variant="body1"> o </Typography>
            <Box sx={{ width: { xs: '100%', sm: '300px' } }}>
              <EmailLoginButton onClick={() => setIsEmailLogin(true)} isExistingUser={isExistingUser} />
            </Box>
          </Box>
        )}
        <Box>
          <Button
            onClick={() => {
              setIsExistingUser(!isExistingUser);
            }}
          >
            {isExistingUser ? 'Crear cuenta' : 'Ya tengo una cuenta'}
          </Button>
          {signUpError && 'data' in signUpError && <Error message={signUpError.data as string} isOpen />}
        </Box>
        <Box>
          {isExistingUser && <Button onClick={handlePasswordRecovery}>Olvidé mi contraseña</Button>}
          {errorPasswordRecovery && 'data' in errorPasswordRecovery && (
            <Error message={errorPasswordRecovery.data as string} isOpen />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

FinancialAdvisorAuthModal.defaultProps = {
  source: undefined,
};

export default FinancialAdvisorAuthModal;
