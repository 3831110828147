import React, { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowForwardIos, Add } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from '@mui/material';

import { SmallIconBtn } from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import { useGetBudgetsV2Query } from 'services/api/budgets.api';
import { Budget } from 'services/types/budget';

const Budgets: FC = () => {
  const { data: budgets, isLoading } = useGetBudgetsV2Query(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  // sort budgets by creation date
  const sortedBudgets = budgets
    ? [...budgets].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    : [];

  const navigateToBudget = (budget: Budget) => {
    navigate('/budget', { state: budget });
  };

  if (isLoading) return <LoadingOverlay visible />;

  return (
    <Box>
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          padding: 2,
        }}
      >
        {sortedBudgets?.map((budget) => (
          <Card key={budget.id} sx={{ minWidth: 200, margin: 1, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {budget.name}
              </Typography>
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <SmallIconBtn onClick={() => navigateToBudget(budget)}>
                  <ArrowForwardIos />
                </SmallIconBtn>
              </Box>
            </CardContent>
          </Card>
        ))}
        <Card sx={{ minWidth: 200, margin: 1 }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Crear presupuesto
            </Typography>
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <SmallIconBtn onClick={() => navigate('/budget/new')}>
                <Add />
              </SmallIconBtn>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Budgets;
