import { getAuth, signInWithRedirect, GoogleAuthProvider } from 'firebase/auth';

import { app } from '../fireBaseConfig';

// Adjust the import based on your file structure

const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });

const signInWithGoogle = async (): Promise<void> => {
  try {
    await signInWithRedirect(auth, googleProvider);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error during Google Sign-In:', error);
  }
};

const signInWithFacebook = async (): Promise<void> => {
  throw new Error('Facebook Sign-In is not implemented');
};

export default async (provider: 'google' | 'facebook'): Promise<void> => {
  const signInFunction = provider === 'google' ? signInWithGoogle : signInWithFacebook;
  return signInFunction();
};
