import { Message } from '../types/whatsApp';
import baseApi from './baseApi';

const publicAccessApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserChatIdentifier: build.query({
      query: () => ({
        url: '/chat/pusher-identifier',
        method: 'GET',
      }),
      transformResponse: (response: { pusherIdentifier: string }) => response.pusherIdentifier,
    }),
    getChatUserMessages: build.query({
      query: () => ({
        url: `/chat/messages`,
        method: 'GET',
      }),
      transformResponse: (response: Message[]) => response,
    }),
    createChatMessage: build.mutation({
      query: (props: { message: string }) => ({
        url: '/chat/messages',
        method: 'POST',
        body: props,
      }),
      transformResponse: (response: { status: string }) => response,
    }),
    sendFileMessage: build.mutation({
      query: (props: { file: File }) => {
        const formData = new FormData();
        formData.append('file', props.file);
        return {
          url: '/chat/file',
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (response: { status: string }) => response,
    }),
    getUserFiles: build.query({
      query: () => ({
        url: '/chat/user-files',
        method: 'GET',
      }),
      transformResponse: (response: Message[]) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserChatIdentifierQuery,
  useGetChatUserMessagesQuery,
  useCreateChatMessageMutation,
  useSendFileMessageMutation,
  useGetUserFilesQuery,
} = publicAccessApi;
