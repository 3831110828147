import React from 'react';

import { Box, Typography } from '@mui/material';

import SectionHeader from 'components/layout/SectionHeader';

import BankStatements from './components/BankStatements';
import Budgets from './components/Budgets';
import Goals from './components/goals';

const FinancialOrder = () => {
  return (
    <div>
      <SectionHeader text="Orden financiero" button={null} />
      <Box display="flex" flexDirection="column" gap={2} px={4} pb={4}>
        <Typography variant="h5" component="div">
          Presupuestos
        </Typography>
        <Budgets />
        <Typography variant="h5" component="div">
          Objetivos
        </Typography>
        <Goals />
        <Typography variant="h5" component="div">
          Cartolas bancarias
        </Typography>
        <BankStatements />
      </Box>
    </div>
  );
};

export default FinancialOrder;
