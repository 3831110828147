import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';

import type { BankOffer } from '@relif/backend';
import { findCMFInstitutionsLogo } from 'assets/data/institutionTypeList';
import { PrimaryBtn } from 'components/UI/Buttons';
import { captureUserEvent } from 'utils/posthogUtils';

import BankClientOfferDetails from './BankClientOfferDetails';
import BankOfferOptionDetails from './BankOfferOptionDetails';
import NewLoanOffer from './NewLoanOffer';
import OfferHeader from './OfferHeader';

type AllowedTypes = 'refinance' | 'new_loan';
interface Props {
  bankOffer: BankOffer;
  setInterested: (id: number, term: number) => void;
  selectedTerm: number | null;
  withLayout?: boolean;
}

const RefinanceOffer: FC<Props> = ({ bankOffer, setInterested, selectedTerm, withLayout = false }) => {
  const [selectedType, setSelectedType] = useState<AllowedTypes | null>(null);
  const navigate = useNavigate();

  const options = bankOffer.options || bankOffer.terms || [];

  let allowedTypes: AllowedTypes[] = bankOffer.isBankClient ? ['refinance'] : ['refinance', 'new_loan'];

  if (!bankOffer.isBankClient && !bankOffer.paymentPlanAmount) {
    allowedTypes = ['new_loan'];
  }

  let amountDescription = bankOffer.interestRate
    ? `${((bankOffer.interestRate || 0) * 100).toFixed(2)}% interés mensual`
    : '';

  if (bankOffer.isBankClient) {
    amountDescription = 'Tasa de interés y cuotas por confirmar';
  }

  const handleNewLoan = () => {
    window.open('https://www.internacional.cl/continuar-compra/5G4f2jOJsfpycILyTUfNsA==', '_blank');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderRadius: 4,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {withLayout && (
          <Box display="flex" justifyContent="flex-start" alignItems="center" width="100%" p={1}>
            <PrimaryBtn
              onClick={() => {
                if (selectedType) {
                  setSelectedType(null);
                } else if (withLayout) {
                  navigate('/debt-profile');
                }
              }}
            >
              Volver
            </PrimaryBtn>
          </Box>
        )}

        {!selectedType && (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
              <img src={findCMFInstitutionsLogo(bankOffer.bank)} alt="logo" width="58px" height="58px" />
              <Typography
                variant="h1"
                sx={{
                  fontSize: 18,
                  width: '100%',
                  mt: 3,
                  fontWeight: 600,
                  color: 'text.primary',
                  textAlign: 'center',
                }}
              >
                {bankOffer.bank}, te está ofreciendo {allowedTypes.length > 1 ? 'dos opciones' : 'esta opción'}:
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {allowedTypes.map((type) => (
                <Box
                  key={type}
                  id="header"
                  sx={{
                    border: '1px solid',
                    borderColor: '#9FC6EA',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 2,
                    px: 3,
                    borderRadius: 4,
                    mt: 6,
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: '#9FC6EA',
                      borderRadius: '20px',
                      width: '75%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      px: 1,
                      height: '50px',
                      mt: '-50px',
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: 18,
                        width: '100%',
                        fontWeight: 400,
                        color: 'primary.main',
                        textAlign: 'center',
                      }}
                    >
                      {type === 'new_loan' ? 'Nuevo crédito' : 'Compra de cartera'}
                    </Typography>
                  </Box>

                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: 16,
                      width: '100%',
                      mt: 1,
                      fontWeight: 400,
                      color: 'text.primary',
                      textAlign: 'justify',
                    }}
                  >
                    {type === 'new_loan'
                      ? 'Puedes pedir un nuevo crédito y el dinero será abonado a tu cuenta corriente'
                      : 'Las deudas que tienes actualmente, se juntan en un nuevo crédito. Tu eliges que deudas refinanciar.'}
                  </Typography>
                  {type === 'new_loan' && (
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: 'text.primary',
                        width: '100%',
                        textAlign: 'left',
                      }}
                    >
                      * Úsalo solo si lo necesitas
                    </Typography>
                  )}
                  <PrimaryBtn
                    variant="contained"
                    sx={{ width: '150px', mt: 2 }}
                    onClick={() => {
                      captureUserEvent('BankOfferFlow: Clicked on a bank offer option', {
                        bank: bankOffer.bank,
                        bankOfferUuid: bankOffer.uuid,
                        bankOfferType: type,
                      });
                      setSelectedType(type);
                    }}
                  >
                    Ver detalles
                  </PrimaryBtn>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {selectedType && allowedTypes.length > 1 && (
          <Box sx={{ width: '100%', px: 2 }}>
            <Typography variant="body1" sx={{ width: '100%', fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Tipo de solicitud
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" py={2}>
              <ToggleButtonGroup
                value={selectedType}
                exclusive
                onChange={(e, newType) => {
                  if (newType !== null) {
                    setSelectedType(newType as 'refinance' | 'new_loan');
                  }
                }}
                aria-label="loan type"
                sx={{ width: '100%' }}
              >
                <ToggleButton value="refinance" aria-label="refinance" sx={{ width: '50%', fontSize: 10 }}>
                  Compra de cartera
                </ToggleButton>
                <ToggleButton value="new_loan" aria-label="new loan" sx={{ width: '50%', fontSize: 10 }}>
                  Nuevo crédito
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
        )}

        {selectedType === 'new_loan' && <NewLoanOffer bankOffer={bankOffer} handleNewLoan={handleNewLoan} />}

        {selectedType === 'refinance' && (
          <>
            <Box sx={{ width: '100%', px: 2 }}>
              <OfferHeader
                title="Compra de cartera"
                amountTitle={bankOffer.bank}
                alternativeText={bankOffer.isBankClient ? 'Ya eres cliente' : ''}
                amount={
                  bankOffer.paymentPlanAmount || bankOffer.cashPaymentAmount
                    ? Number(bankOffer.paymentPlanAmount || bankOffer.cashPaymentAmount)
                    : 0
                }
                amountDescription={amountDescription}
              />
            </Box>
            <Box mt={2} px={2} width="100%">
              {bankOffer.isBankClient && (
                <BankClientOfferDetails bankOfferId={bankOffer.id} setInterested={setInterested} />
              )}
              {bankOffer.terms && bankOffer.terms.length > 0 && (
                <BankOfferOptionDetails
                  bankOffer={bankOffer}
                  options={options}
                  selectedTerm={selectedTerm}
                  setInterested={setInterested}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

RefinanceOffer.defaultProps = {
  withLayout: false,
};

export default RefinanceOffer;
