/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useRef, FC } from 'react';

import { Add, ArrowForwardIos, Delete } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from '@mui/material';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { DeleteBtn, SmallIconBtn } from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import FinancialStatusModal from 'pages/FinancialHealth/EditFinancialStatusModal';
import { useGetFinancialStatementQuery } from 'services/api/financialStatements.api';
import { useDeleteGoalMutation, useGetGoalsMutation, useGetGoalTemplatesQuery } from 'services/api/goals.api';
import { Goal } from 'services/types/goals';

import AddGoalModal from './AddGoalModal';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface AlertInterface {
  severity: AlertColor;
  text: string;
}

const Goals: FC = () => {
  const [goals, setGoals] = useState<Goal[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openNewObjective, setOpenNewObjective] = useState(false);
  const [alert, setAlert] = useState<AlertInterface>({
    severity: 'success',
    text: '',
  });
  const [editFinancialStatusModalOpen, setEditFinancialStatusModalOpen] = useState(false);
  const [getGoals, { data, isLoading }] = useGetGoalsMutation();
  const date = new Date();
  const [deleteGoal, { isLoading: isDeletingGoal }] = useDeleteGoalMutation();
  date.setMonth(date.getMonth() - 1);
  const { data: financialStatementData } = useGetFinancialStatementQuery(null);
  const { data: goalTemplatesData, refetch: refetchGoalTemplates } = useGetGoalTemplatesQuery(null);

  useEffect(() => {
    const getGoalsData = async () => {
      await getGoals(null);
    };
    // eslint-disable-next-line no-void
    void getGoalsData();
  }, []);

  useEffect(() => {
    if (data) {
      setGoals(data);
    }
  }, [data]);

  const addGoal = (newGoal: Goal) => {
    setGoals([...goals, newGoal]);
  };

  const handleDeleteGoal = (id: number) => {
    deleteGoal(id)
      .then(() => {
        getGoals(null).catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };
  const handleAlert = (severity: AlertColor, text: string) => {
    setOpenAlert(true);
    setAlert({ severity, text });
  };

  const prevOpenNewObjective = useRef(openNewObjective);

  // refetch goal templates when goals modal is closed
  useEffect(() => {
    if (prevOpenNewObjective.current === true && openNewObjective === false) {
      refetchGoalTemplates().catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    }
    prevOpenNewObjective.current = openNewObjective;
  }, [openNewObjective]);

  const prevOpenEditFinancialStatusModal = useRef(editFinancialStatusModalOpen);

  // refetch goal templates when edit financial status modal is closed
  useEffect(() => {
    if (prevOpenEditFinancialStatusModal.current === true && editFinancialStatusModalOpen === false) {
      refetchGoalTemplates().catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    }
    prevOpenEditFinancialStatusModal.current = editFinancialStatusModalOpen;
  }, [editFinancialStatusModalOpen]);

  if (isLoading || isDeletingGoal) {
    return <LoadingOverlay visible />;
  }

  return (
    <Box>
      <Snackbar open={openAlert} autoHideDuration={4000} onClose={() => setOpenAlert(false)}>
        <Alert onClose={() => setOpenAlert(false)} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.text}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          padding: 2,
          width: '100%',
        }}
      >
        {goals?.map((goal) => (
          <Card key={goal.id} sx={{ minWidth: 200, maxWidth: 200, margin: 1, borderRadius: 2 }}>
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h5">{goal.name}</Typography>
              <Typography variant="body2" color="text.secondary">
                Monto: {goal.amount}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Plazo: {goal.term} meses
              </Typography>
              <Box display="flex" justifyContent="space-between" mt={1} alignItems="flex-end" height="100%">
                <DeleteBtn onClick={() => handleDeleteGoal(goal.id)} color="error">
                  <Delete />
                </DeleteBtn>
                <SmallIconBtn onClick={() => handleAlert('info', 'Goal details')}>
                  <ArrowForwardIos />
                </SmallIconBtn>
              </Box>
            </CardContent>
          </Card>
        ))}
        <Card sx={{ minWidth: 200, margin: 1 }}>
          <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5">Crear objetivo</Typography>
            <Box display="flex" justifyContent="flex-end" mt={1} height="100%" alignItems="flex-end">
              <SmallIconBtn onClick={() => setOpenNewObjective(true)}>
                <Add />
              </SmallIconBtn>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {goalTemplatesData && (
        <AddGoalModal
          addGoal={addGoal}
          handleAlert={handleAlert}
          open={openNewObjective}
          setOpen={setOpenNewObjective}
          templatesData={goalTemplatesData}
          prevGoal={null}
          handleUpdateGoal={() => {}}
        />
      )}
      <FinancialStatusModal
        open={editFinancialStatusModalOpen}
        setModalOpen={setEditFinancialStatusModalOpen}
        financialStatementToEdit={financialStatementData || null}
        setFinancialStatement={() => {}}
      />
    </Box>
  );
};

export default Goals;
