// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDLwRKdM6xTNN17Y7RUbJQ8-l8SzXqsH5M',
  authDomain: 'www.relif.cl',
  projectId: 'relif-a0624',
  // storageBucket: 'relif-a0624.firebasestorage.app',
  messagingSenderId: '123699115531',
  appId: '1:123699115531:web:d9ee1dbcb820093079b90e',
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { app, auth };
